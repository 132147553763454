<template>
  <div id="round-winlose-report" class="container-fluid pb-5 position-relative">
    <div class="page-header mb-1">
      <strong>คาดคะเน ได้-เสีย</strong>
    </div>
    <b-card no-body class="mb-1 position-relative">
      <b-card-header header-tag="nav" class="d-flex justify-content-between">
        <h5 class="card-title mb-0">
          <i class="fas fa-info-circle text-danger mr-2"></i>
          <span class="text-dark mr-2">เฉพาะ</span>
          <span class="text-primary mr-2">{{ roundName }}</span>
          <span class="text-dark mr-2">วันที่</span>
          <span class="text-primary mr-2">{{ roundDate }}</span>
          <small class="text-secondary">(เปลี่ยนได้ที่แถบด้านบน)</small>
        </h5>

        <span>
          <button type="button" class="btn alert-warning border-warning btn-sm py-0" @click="reload(false)">รีเฟรชในอีก {{countDown}} วินาที</button>
        </span>
      </b-card-header>

      <RoundWinLoseAll :roundData="roundData" :setReceive="setReceive" :data="dataItems" :total="total" :isLoading="isLoading" @reload="reload" />
    </b-card>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EBEDEF"
      :height="30"
      :width="30"
      color="#007bff"
    />
  </div>
</template>
<script>
import moment from '@/helpers/moment'
import Swal from 'sweetalert2'
import ReportService from "@/services/ReportService"
import RoundService from "@/services/RoundService"
import _ from 'lodash'

import RoundWinLoseAll from './components/RoundWinLoseAll'
import cAlert from '@/helpers/alert'

export default {
  name: 'RoundWinLose2',
  components: {
    RoundWinLoseAll
  },
  data() {
    return {
      data: null,
      roundData: null,
      isLoading: false,
      activeTab: 'WinLoseTop',
      setReceive: {
        threeNumberTop: 0,
        threeNumberTode: 0,
        twoNumberTop: 0,
        twoNumberBottom: 0,
        runTop: 0,
        runBottom: 0
      },
      refreshInterval: null,
      countDown: 299
    }
  },
  computed: {
    roundId() {
      return this.$store.state.globalMarket?.round?.roundId
    },
    roundName() {
      if(!this.data)
        return ''

      return `[${this.data.round.note.groupTitle}] ${this.data.round.note.marketTitle}`
    },
    roundDate() {
      if(!this.data)
        return ''

      return moment(this.data.round.roundDate.date).format("DD/MM/YYYY")
    },
    dataItems() {
      const items = this.data?.items || []

      /**
       * 3 ตัวบน
       */
      const threeNumberTopObjs = items.filter(item => item._id.type==='threeNumberTop')
      .map((item)=>{
        item.winlose = item.reward + this.total.threeNumberTop.total
        return item
      })
      .reduce((ObjItems, item)=>{
        ObjItems[item._id.number] = item
        return ObjItems
      }, {})

      /**
       * 3 ตัวโต๊ด
       */
      const threeNumberTodeItems = items.filter(item => item._id.type==='threeNumberTode')
      const threeNumberTodeObjects = threeNumberTodeItems.reduce((todeItems, item)=>{
        todeItems[item._id.number] = item.reward
        return todeItems
      }, {})

      const threeNumberTodeObjs = threeNumberTodeItems.map((item)=>{
        const sps = item._id.number.split("")
        const todeWinNumbers = _.uniq([
          `${sps[0]}${sps[1]}${sps[2]}`,
          `${sps[0]}${sps[2]}${sps[1]}`,
          `${sps[1]}${sps[0]}${sps[2]}`,
          `${sps[1]}${sps[2]}${sps[0]}`,
          `${sps[2]}${sps[0]}${sps[1]}`,
          `${sps[2]}${sps[1]}${sps[0]}`
        ])

        /**
         * ยอดจ่าย 3 ตัวโต๊ดทั้งหมด
         */
        const todeWonAmount = todeWinNumbers.reduce((total, number)=>{
          total += (threeNumberTodeObjects?.[number] ?? 0)
          return total
        }, 0)

        item.setNumbers = todeWinNumbers
        item.winlose = todeWonAmount + this.total.threeNumberTode.total
        return item
      })
      .reduce((ObjItems, item)=>{
        ObjItems[item._id.number] = item
        return ObjItems
      }, {})

      /**
       * สร้างตัวเลข 3 ตัว ให้ครบทุกแถว
       */
      const threeNumberTop = []
      const threeNumberTode = []
      for(let i=0; i<=999; i++) {
        const threeNumber = i.toString().padStart(3, '0')

        /**
         * 3 ตัวบน
         */
        const topNumberObj = threeNumberTopObjs?.[threeNumber] ?? {
          "_id": {
            "type": "threeNumberTop",
            "number": threeNumber
          },
          "agentAmount": 0,
          "agentCommission": 0,
          "agentTotal": 0,
          "reward": 0,
          "amount": 0,
          "count": 0,
          "text": "3 ตัวบน",
          "winlose": this.total.threeNumberTop.total
        }
        threeNumberTop.push(topNumberObj)

        /**
         * 3 ตัวโต๊ด
         */
        const todeNumberObj1 = threeNumberTodeObjs?.[threeNumber] ?? null
        if(todeNumberObj1) {
          threeNumberTode.push(todeNumberObj1)
        }else{
          /**
           * ไม่มียอดแทงเข้ามา ต้องหาจากกลุ่มเลขโต๊ดอื่นๆ
           */
          const sps = threeNumber.split("")
          const todeNumbers = _.uniq([
            `${sps[0]}${sps[1]}${sps[2]}`,
            `${sps[0]}${sps[2]}${sps[1]}`,
            `${sps[1]}${sps[0]}${sps[2]}`,
            `${sps[1]}${sps[2]}${sps[0]}`,
            `${sps[2]}${sps[0]}${sps[1]}`,
            `${sps[2]}${sps[1]}${sps[0]}`
          ])
          const todeAmount = todeNumbers.find((num)=>{
            return threeNumberTodeObjs?.[num] ?? false
          })

          const todeNumberObj2 = {
            "_id": {
              "type": "threeNumberTode",
              "number": threeNumber
            },
            "agentAmount": 0,
            "agentCommission": 0,
            "agentTotal": 0,
            "reward": 0,
            "amount": 0,
            "count": 0,
            "text": "3 ตัวโต๊ด",
            "winlose": todeAmount?.winlose ?? this.total.threeNumberTode.total
          }
          threeNumberTode.push(todeNumberObj2)
        }
      }

      /**
       * 2 ตัวบน
       */
      const twoNumberTopObjs = items.filter(item => item._id.type==='twoNumberTop')
      .map((item)=>{
        item.winlose = item.reward + this.total.twoNumberTop.total
        return item
      })
      .reduce((ObjItems, item)=>{
        ObjItems[item._id.number] = item
        return ObjItems
      }, {})

      /**
       * 2 ตัวล่าง
       */
      const twoNumberBottomObjs = items.filter(item => item._id.type==='twoNumberBottom')
      .map((item)=>{
        item.winlose = item.reward + this.total.twoNumberBottom.total
        return item
      })
      .reduce((ObjItems, item)=>{
        ObjItems[item._id.number] = item
        return ObjItems
      }, {})

      /**
       * สร้างเลข 2 ตัว ให้ครบทุกเลข
       */
      const twoNumberTop = []
      const twoNumberBottom = []
      for(let i=0; i<=99; i++) {
        const twoNumber = i.toString().padStart(2, '0')

        /**
         * 2 ตัวบน
         */
        const topNumberObj = twoNumberTopObjs?.[twoNumber] ?? {
          "_id": {
            "type": "twoNumberTop",
            "number": twoNumber
          },
          "agentAmount": 0,
          "agentCommission": 0,
          "agentTotal": 0,
          "reward": 0,
          "amount": 0,
          "count": 0,
          "text": "2 ตัวบน",
          "winlose": this.total.twoNumberTop.total
        }
        twoNumberTop.push(topNumberObj)

        /**
         * 2 ตัวล่าง
         */
        const botNumberObj = twoNumberBottomObjs?.[twoNumber] ?? {
          "_id": {
            "type": "twoNumberBottom",
            "number": twoNumber
          },
          "agentAmount": 0,
          "agentCommission": 0,
          "agentTotal": 0,
          "reward": 0,
          "amount": 0,
          "count": 0,
          "text": "2 ตัวล่าง",
          "winlose": this.total.twoNumberBottom.total
        }
        twoNumberBottom.push(botNumberObj)
      }

      /**
       * วิ่งบน
       */
      const runTopObjs = items.filter(item => item._id.type==='runTop')
      .map((item)=>{
        item.winlose = item.reward + this.total.runTop.total
        return item
      })
      .reduce((ObjItems, item)=>{
        ObjItems[item._id.number] = item
        return ObjItems
      }, {})

      /**
       * วิ่งล่าง
       */
      const runBottomObjs = items.filter(item => item._id.type==='runBottom')
      .map((item)=>{
        item.winlose = item.reward + this.total.runBottom.total
        return item
      })
      .reduce((ObjItems, item)=>{
        ObjItems[item._id.number] = item
        return ObjItems
      }, {})

      /**
       * สร้างเลข 1 ตัว ให้ครบทุกเลข
       */
      const runTop = []
      const runBottom = []
      for(let i=0; i<=9; i++) {
        const oneNumber = i.toString()

        /**
         * วิ่งบน
         */
        const topNumberObj = runTopObjs?.[oneNumber] ?? {
          "_id": {
            "type": "runTop",
            "number": oneNumber
          },
          "agentAmount": 0,
          "agentCommission": 0,
          "agentTotal": 0,
          "reward": 0,
          "amount": 0,
          "count": 0,
          "text": "วิ่งบน",
          "winlose": this.total.runTop.total
        }
        runTop.push(topNumberObj)

        /**
         * วิ่งล่าง
         */
        const botNumberObj = runBottomObjs?.[oneNumber] ?? {
          "_id": {
            "type": "runBottom",
            "number": oneNumber
          },
          "agentAmount": 0,
          "agentCommission": 0,
          "agentTotal": 0,
          "reward": 0,
          "amount": 0,
          "count": 0,
          "text": "วิ่งล่าง",
          "winlose": this.total.runBottom.total
        }
        runBottom.push(botNumberObj)
      }

      return {
        threeNumberTop,
        threeNumberTode,
        twoNumberTop,
        twoNumberBottom,
        runTop,
        runBottom
      }
    },
    total() {
      return (this.data?.items || []).reduce((total, item)=>{
        total[item._id.type].amount += item.agentAmount
        total[item._id.type].comission += item.agentCommission
        total[item._id.type].total += item.agentTotal

        total.overall.amount += item.agentAmount
        total.overall.comission += item.agentCommission
        total.overall.total += item.agentTotal
        return total
      }, {
        threeNumberTop: {
          amount: 0,
          comission: 0,
          total: 0
        },
        threeNumberTode: {
          amount: 0,
          comission: 0,
          total: 0
        },
        twoNumberTop: {
          amount: 0,
          comission: 0,
          total: 0
        },
        runTop: {
          amount: 0,
          comission: 0,
          total: 0
        },
        twoNumberBottom: {
          amount: 0,
          comission: 0,
          total: 0
        },
        runBottom: {
          amount: 0,
          comission: 0,
          total: 0
        },
        overall: {
          amount: 0,
          comission: 0,
          total: 0
        }
      })
    }
  },
  watch: {
    roundId(n, o) {
      if(n) {
        this.reload()
      }
    }
  },
  methods: {
    getReport(silent=false) {

      /**
       * ตรวจสอบประเภทหวย
       */
      if(this.$store.state.globalMarket?.marketType==='lottoset') {
        return this.$router.push({name: 'SetsOverall'})
      }

      if(!silent)
        this.isLoading = true

      clearInterval(this.refreshInterval)
      ReportService.getRoundOverall(this.roundId)
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
        this.countDownRefresh()
      })
    },
    loadRoundData() {
      if(!this.roundId)
        return

      RoundService.getRoundSetting(this.roundId, true)
      .then((response)=>{
        if(response.success) {
          this.roundData = response.data
          this.setReceive = this.roundData.receive
        }else{
          throw new Error(response.data)
        }
      })
    },
    reload(silent=false) {
      this.loadRoundData()
      this.getReport(silent)
    },
    countDownRefresh() {
      this.countDown = 299
      clearInterval(this.refreshInterval)
      this.refreshInterval = setInterval(()=>{
        this.countDown--
        if(this.countDown < 1) {
          this.reload(true)
        }
      }, 1000)
    }
  },
  mounted() {
    this.reload()
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval)
  }
}
</script>
